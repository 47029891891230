import api from "./api";

const companies = {
    getCompanies(query) {
        return api.get("/companies", { params: query});
    },
    getCompany(id) {
        return api.get(`/companies/${id}`);
    },
    postCompany(body) {
        return api.post("/companies", body);
    },
    putCompany(id, body) {
        return api.post(`/companies/${id}`, body);
    },
};

export default companies;
